* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" sans-serif;
}
nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    rgb(8, 169, 205),
    rgb(81, 132, 132),
    rgb(40, 129, 170)
  );
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 1.5rem;
}

.home-section {
  background-color: rgba(101, 181, 180, 0.9);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.home-grid-item {
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.home-title {
  color: white;
  padding: 5%;
}

.home-subtitle {
  color: white;
  padding: 1.5rem;
}

.home-info {
  color: white;
}

#footer-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

footer {
  background-color: black;
  color: white;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-decoration: none;
}
footer div div p a {
  text-decoration: none;
  color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-footer {
    display: none;
  }
  .mobile-footer {
    display: block;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem;
    padding: 1.5rem;
  }
  .mobile-footer div p {
    padding: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main-footer {
    display: none;
  }
  .mobile-footer {
    display: block;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem;
    padding: 1.5rem;
  }
  .mobile-footer div p {
    padding: 1rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem;
    padding: 1.5rem;
  }
  .main-footer div p {
    padding: 1rem;
  }
  .mobile-footer {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem;
    padding: 1.5rem;
  }
  .main-footer div p {
    padding: 1rem;
  }
  .mobile-footer {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .main-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem;
    padding: 1.5rem;
  }
  .main-footer div p {
    padding: 1rem;
  }
  .mobile-footer {
    display: none;
  }
}
